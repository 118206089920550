exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-ai-driven-solutions-js": () => import("./../../../src/pages/ai-driven-solutions.js" /* webpackChunkName: "component---src-pages-ai-driven-solutions-js" */),
  "component---src-pages-ai-powered-chatbots-js": () => import("./../../../src/pages/ai-powered-chatbots.js" /* webpackChunkName: "component---src-pages-ai-powered-chatbots-js" */),
  "component---src-pages-analytics-visualization-js": () => import("./../../../src/pages/analytics-visualization.js" /* webpackChunkName: "component---src-pages-analytics-visualization-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-cloud-base-bpa-js": () => import("./../../../src/pages/cloud-base-bpa.js" /* webpackChunkName: "component---src-pages-cloud-base-bpa-js" */),
  "component---src-pages-cloud-solutions-js": () => import("./../../../src/pages/cloud-solutions.js" /* webpackChunkName: "component---src-pages-cloud-solutions-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cost-optimization-js": () => import("./../../../src/pages/cost-optimization.js" /* webpackChunkName: "component---src-pages-cost-optimization-js" */),
  "component---src-pages-data-ai-js": () => import("./../../../src/pages/data-ai.js" /* webpackChunkName: "component---src-pages-data-ai-js" */),
  "component---src-pages-data-ai-solutions-js": () => import("./../../../src/pages/data-ai-solutions.js" /* webpackChunkName: "component---src-pages-data-ai-solutions-js" */),
  "component---src-pages-data-ai-strategy-js": () => import("./../../../src/pages/data-ai-strategy.js" /* webpackChunkName: "component---src-pages-data-ai-strategy-js" */),
  "component---src-pages-data-driven-bpa-js": () => import("./../../../src/pages/data-driven-bpa.js" /* webpackChunkName: "component---src-pages-data-driven-bpa-js" */),
  "component---src-pages-data-driven-rpa-js": () => import("./../../../src/pages/data-driven-rpa.js" /* webpackChunkName: "component---src-pages-data-driven-rpa-js" */),
  "component---src-pages-data-engineering-js": () => import("./../../../src/pages/data-engineering.js" /* webpackChunkName: "component---src-pages-data-engineering-js" */),
  "component---src-pages-data-governance-js": () => import("./../../../src/pages/data-governance.js" /* webpackChunkName: "component---src-pages-data-governance-js" */),
  "component---src-pages-data-integration-js": () => import("./../../../src/pages/data-integration.js" /* webpackChunkName: "component---src-pages-data-integration-js" */),
  "component---src-pages-data-lake-js": () => import("./../../../src/pages/data-lake.js" /* webpackChunkName: "component---src-pages-data-lake-js" */),
  "component---src-pages-data-migration-js": () => import("./../../../src/pages/data-migration.js" /* webpackChunkName: "component---src-pages-data-migration-js" */),
  "component---src-pages-data-observability-js": () => import("./../../../src/pages/data-observability.js" /* webpackChunkName: "component---src-pages-data-observability-js" */),
  "component---src-pages-data-pipeline-js": () => import("./../../../src/pages/data-pipeline.js" /* webpackChunkName: "component---src-pages-data-pipeline-js" */),
  "component---src-pages-data-quality-js": () => import("./../../../src/pages/data-quality.js" /* webpackChunkName: "component---src-pages-data-quality-js" */),
  "component---src-pages-data-warehouse-js": () => import("./../../../src/pages/data-warehouse.js" /* webpackChunkName: "component---src-pages-data-warehouse-js" */),
  "component---src-pages-dataops-solutions-js": () => import("./../../../src/pages/dataops-solutions.js" /* webpackChunkName: "component---src-pages-dataops-solutions-js" */),
  "component---src-pages-devops-js": () => import("./../../../src/pages/devops.js" /* webpackChunkName: "component---src-pages-devops-js" */),
  "component---src-pages-disaster-recovery-js": () => import("./../../../src/pages/disaster-recovery.js" /* webpackChunkName: "component---src-pages-disaster-recovery-js" */),
  "component---src-pages-edge-analytics-js": () => import("./../../../src/pages/edge-analytics.js" /* webpackChunkName: "component---src-pages-edge-analytics-js" */),
  "component---src-pages-edge-data-management-js": () => import("./../../../src/pages/edge-data-management.js" /* webpackChunkName: "component---src-pages-edge-data-management-js" */),
  "component---src-pages-edge-integration-js": () => import("./../../../src/pages/edge-integration.js" /* webpackChunkName: "component---src-pages-edge-integration-js" */),
  "component---src-pages-edge-intelligence-js": () => import("./../../../src/pages/edge-intelligence.js" /* webpackChunkName: "component---src-pages-edge-intelligence-js" */),
  "component---src-pages-edge-visualization-js": () => import("./../../../src/pages/edge-visualization.js" /* webpackChunkName: "component---src-pages-edge-visualization-js" */),
  "component---src-pages-frontend-developer-js": () => import("./../../../src/pages/frontend-developer.js" /* webpackChunkName: "component---src-pages-frontend-developer-js" */),
  "component---src-pages-genai-data-foundation-js": () => import("./../../../src/pages/genai-data-foundation.js" /* webpackChunkName: "component---src-pages-genai-data-foundation-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-infocion-labs-js": () => import("./../../../src/pages/infocion-labs.js" /* webpackChunkName: "component---src-pages-infocion-labs-js" */),
  "component---src-pages-intelligent-automation-js": () => import("./../../../src/pages/intelligent-automation.js" /* webpackChunkName: "component---src-pages-intelligent-automation-js" */),
  "component---src-pages-low-code-analytics-js": () => import("./../../../src/pages/low-code-analytics.js" /* webpackChunkName: "component---src-pages-low-code-analytics-js" */),
  "component---src-pages-low-code-bpa-js": () => import("./../../../src/pages/low-code-bpa.js" /* webpackChunkName: "component---src-pages-low-code-bpa-js" */),
  "component---src-pages-low-code-development-js": () => import("./../../../src/pages/low-code-development.js" /* webpackChunkName: "component---src-pages-low-code-development-js" */),
  "component---src-pages-machine-learning-ai-js": () => import("./../../../src/pages/machine-learning-ai.js" /* webpackChunkName: "component---src-pages-machine-learning-ai-js" */),
  "component---src-pages-mobile-development-js": () => import("./../../../src/pages/mobile-development.js" /* webpackChunkName: "component---src-pages-mobile-development-js" */),
  "component---src-pages-predictive-analytics-js": () => import("./../../../src/pages/predictive-analytics.js" /* webpackChunkName: "component---src-pages-predictive-analytics-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-real-time-analytics-js": () => import("./../../../src/pages/real-time-analytics.js" /* webpackChunkName: "component---src-pages-real-time-analytics-js" */),
  "component---src-pages-risk-compliance-analytics-js": () => import("./../../../src/pages/risk-compliance-analytics.js" /* webpackChunkName: "component---src-pages-risk-compliance-analytics-js" */),
  "component---src-pages-single-blog-js": () => import("./../../../src/pages/single-blog.js" /* webpackChunkName: "component---src-pages-single-blog-js" */),
  "component---src-pages-submit-resume-js": () => import("./../../../src/pages/submit-resume.js" /* webpackChunkName: "component---src-pages-submit-resume-js" */),
  "component---src-pages-term-conditions-js": () => import("./../../../src/pages/term-conditions.js" /* webpackChunkName: "component---src-pages-term-conditions-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-ui-ux-design-js": () => import("./../../../src/pages/ui-ux-design.js" /* webpackChunkName: "component---src-pages-ui-ux-design-js" */),
  "component---src-pages-web-development-js": () => import("./../../../src/pages/web-development.js" /* webpackChunkName: "component---src-pages-web-development-js" */)
}

